import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next";
import Navigation from "../Navigation"
import Mailto from "react-protected-mailto"

require ('./header.scss')

export default ({localeData}) => {
    // Will use default Namespace.
    const { t } = useTranslation();
    return (
        <header>
            <div className="default-header-area scroll-check header-sticky">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-xl-2 col-sm-8 col-md-3">
                            <div className="header-logo">
                                <Link to={t('home-link')}>
                                    <img className="tiliana_logo" src="/images/logo/hotel_tiliana_black_hh.svg" alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-9">
                            <div className="header-menu-area">
                                <Navigation localeData={localeData}/>
                            </div>
                        </div>
                        <div className="d-none d-xl-block col-xl-2">
                            <div className="contact-phone talkbubble float-right">
                                <i className="fa fa-phone fa-2x" aria-hidden="true"></i>
                                <Mailto tel="+36 1 391 0027" />
                            </div>
                        </div>
                        <div className="book-now-btn text-right d-none">
                            <a className="btn">
                                {t('book_now')}
                                <img src="/images/logo/tiliana_logo_gold_sm.png" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">  
                            <div className="mobile-menu d-lg-none d-xl-none"></div>  
                        </div>
                    </div>
                </div>
                <Link className="tender-sticker" to={t('tender-link')}>
                    <span className="sr-only">Pályázatok</span>
                    <img src="/images/szechenyi2020.png" alt="Tiliana Pályázatok" title="Tiliana Pályázatok"/>
                </Link>
            </div>
        </header>
        
    );
};
