import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
import './language-switcher.scss'

const LanguageSwitcher = ({ localeData }) => {

  const changeLng = (newLocale) => {
    newLocale = (newLocale === 'GB') ? 'en' : newLocale.toLowerCase();
    document.location.href = localeData.relativeSlugs[newLocale]
  }

  const getLangCode = (locale) => {
    return (locale === "en") ? "GB" : locale.toUpperCase()
  }

  return (
    <ReactFlagsSelect 
      defaultCountry={getLangCode(localeData.locale)}
      showSelectedLabel={false}
      showOptionLabel={false}
      searchable={false} 
      onSelect={changeLng} 
      countries={localeData.availableLocales.filter(({enabled}) => enabled === undefined || enabled !== false).map(({value}) => getLangCode(value))}
    />
  )};

export default LanguageSwitcher