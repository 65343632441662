import * as React from "react"
import { BreakpointProvider } from 'react-socks';
import Meta from "../Meta"
import Footer from "../Footer"
import Header from "../Header"
import { clientInit } from "./client-init"

class Layout extends React.Component {
  
  componentDidMount() {
    clientInit(this.props.isHome)
  }
  
  render() {
    const {localeData, title, children, keywords, description} = this.props
    return (
      <BreakpointProvider>
        <Meta lang={localeData.locale} title={title} keywords={keywords} description={description}/>
        <Header localeData={localeData} />
        { children }
        <Footer/>
      </BreakpointProvider>
    )
  }
}

export default Layout;