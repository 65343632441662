export function meanMenuInit() {
    
		const $ = require('jquery')
		window.jQuery = $;
		window.$ = $;

		$(document).ready(() => { 
        $('.header-menu-area nav').meanmenu({
          meanMenuContainer: '.mobile-menu',
          meanScreenWidth: "991"
        });
    });

		$.fn.meanmenu = function (options) {
				var defaults = {
						meanMenuTarget: $(this),
						meanMenuContainer: 'body',
						meanMenuClose: "X",
						meanMenuCloseSize: "18px",
						meanMenuOpen: "<span /><span /><span />",
						meanRevealPosition: "right",
						meanRevealPositionDistance: "0",
						meanRevealColour: "",
						meanScreenWidth: "480",
						meanNavPush: "",
						meanShowChildren: true,
						meanExpandableChildren: true, 
						meanExpand: "+", 
						meanContract: "-", 
						meanRemoveAttrs: false, 
						onePage: false, 
						meanDisplay: "block", 
						removeElements: "" 
				};
				options = $.extend(defaults, options);

				var currentWidth = window.innerWidth || document.documentElement.clientWidth;

				return this.each(function () {
						var meanMenu = options.meanMenuTarget;
						var meanContainer = options.meanMenuContainer;
						var meanMenuClose = options.meanMenuClose;
						var meanMenuCloseSize = options.meanMenuCloseSize;
						var meanMenuOpen = options.meanMenuOpen;
						var meanRevealPosition = options.meanRevealPosition;
						var meanRevealPositionDistance = options.meanRevealPositionDistance;
						var meanRevealColour = options.meanRevealColour;
						var meanScreenWidth = options.meanScreenWidth;
						var meanNavPush = options.meanNavPush;
						var meanRevealClass = ".meanmenu-reveal";
						var meanShowChildren = options.meanShowChildren;
						var meanExpandableChildren = options.meanExpandableChildren;
						var meanExpand = options.meanExpand;
						var meanContract = options.meanContract;
						var meanRemoveAttrs = options.meanRemoveAttrs;
						var onePage = options.onePage;
						var meanDisplay = options.meanDisplay;
						var removeElements = options.removeElements;

						var isMobile = false;
						if ( (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Android/i)) || (navigator.userAgent.match(/Blackberry/i)) || (navigator.userAgent.match(/Windows Phone/i)) ) {
								isMobile = true;
						}

						if ( (navigator.userAgent.match(/MSIE 8/i)) || (navigator.userAgent.match(/MSIE 7/i)) ) {
								$('html').css("overflow-y" , "scroll");
						}

						var meanRevealPos = "";
						var meanCentered = function() {
							if (meanRevealPosition === "center") {
								var newWidth = window.innerWidth || document.documentElement.clientWidth;
								var meanCenter = ( (newWidth/2)-22 )+"px";
								meanRevealPos = "left:" + meanCenter + ";right:auto;";

								if (!isMobile) {
									$('.meanmenu-reveal').css("left",meanCenter);
								} else {
									$('.meanmenu-reveal').animate({
											left: meanCenter
									});
								}
							}
						};

						var menuOn = false;
						var meanMenuExist = false;


						if (meanRevealPosition === "right") {
								meanRevealPos = "right:" + meanRevealPositionDistance + ";left:auto;";
						}
						if (meanRevealPosition === "left") {
								meanRevealPos = "left:" + meanRevealPositionDistance + ";right:auto;";
						}

						meanCentered();

						var $navreveal = "";

						var meanInner = function() {
								if ($($navreveal).is(".meanmenu-reveal.meanclose")) {
										$navreveal.html(meanMenuClose);
								} else {
										$navreveal.html(meanMenuOpen);
								}
						};

						var meanOriginal = function() {
							$('.mean-bar,.mean-push').remove();
							$(meanContainer).removeClass("mean-container");
							$(meanMenu).css('display', meanDisplay);
							menuOn = false;
							meanMenuExist = false;
							$(removeElements).removeClass('mean-remove');
						};

						var showMeanMenu = function() {
								var meanStyles = "background:"+meanRevealColour+";color:"+meanRevealColour+";"+meanRevealPos;
								if (currentWidth <= meanScreenWidth) {
								$(removeElements).addClass('mean-remove');
									meanMenuExist = true;

									$(meanContainer).addClass("mean-container");
									$('.mean-container').prepend('<div class="mean-bar"><a href="#nav" class="meanmenu-reveal" style="'+meanStyles+'">Show Navigation</a><nav class="mean-nav"></nav></div>');

									var meanMenuContents = $(meanMenu).html();
									$('.mean-nav').html(meanMenuContents);

									if(meanRemoveAttrs) {
										$('nav.mean-nav ul, nav.mean-nav ul *').each(function() {

											if ($(this).is('.mean-remove')) {
												$(this).attr('class', 'mean-remove');
											} else {
												$(this).removeAttr("class");
											}
											$(this).removeAttr("id");
										});
									}

									$(meanMenu).before('<div class="mean-push" />');
									$('.mean-push').css("margin-top",meanNavPush);

									$(meanMenu).hide();
									$(".meanmenu-reveal").show();

									$(meanRevealClass).html(meanMenuOpen);
									$navreveal = $(meanRevealClass);

									$('.mean-nav ul').hide();

									if(meanShowChildren) {
											if(meanExpandableChildren){
												$('.mean-nav ul ul').each(function() {
														if($(this).children().length){
																$(this,'li:first').parent().append('<a class="mean-expand" href="#" style="font-size: '+ meanMenuCloseSize +'">'+ meanExpand +'</a>');
														}
												});
												$('.mean-expand').on("click",function(e){
														e.preventDefault();
															if ($(this).hasClass("mean-clicked")) {
																	$(this).text(meanExpand);
																$(this).prev('ul').slideUp(300, function(){});
														} else {
																$(this).text(meanContract);
																$(this).prev('ul').slideDown(300, function(){});
														}
														$(this).toggleClass("mean-clicked");
												});
											} else {
													$('.mean-nav ul ul').show();
											}
									} else {
											$('.mean-nav ul ul').hide();
									}

									$('.mean-nav ul li').last().addClass('mean-last');
									$navreveal.removeClass("meanclose");
									$($navreveal).click(function(e){
										e.preventDefault();
								if( menuOn === false ) {
												$navreveal.css("text-align", "center");
												$navreveal.css("text-indent", "0");
												$navreveal.css("font-size", meanMenuCloseSize);
												$('.mean-nav ul:first').slideDown();
												menuOn = true;
										} else {
											$('.mean-nav ul:first').slideUp();
											menuOn = false;
										}
											$navreveal.toggleClass("meanclose");
											meanInner();
											$(removeElements).addClass('mean-remove');
									});

									if ( onePage ) {
										$('.mean-nav ul > li > a:first-child').on( "click" , function () {
											$('.mean-nav ul:first').slideUp();
											menuOn = false;
											$($navreveal).toggleClass("meanclose").html(meanMenuOpen);
										});
									}
							} else {
								meanOriginal();
							}
						};

						if (!isMobile) {
								$(window).resize(function () {
										currentWidth = window.innerWidth || document.documentElement.clientWidth;
										if (currentWidth > meanScreenWidth) {
												meanOriginal();
										} else {
											meanOriginal();
										}
										if (currentWidth <= meanScreenWidth) {
												showMeanMenu();
												meanCentered();
										} else {
											meanOriginal();
										}
								});
						}

					$(window).resize(function () {
								currentWidth = window.innerWidth || document.documentElement.clientWidth;

								if (!isMobile) {
										meanOriginal();
										if (currentWidth <= meanScreenWidth) {
												showMeanMenu();
												meanCentered();
										}
								} else {
										meanCentered();
										if (currentWidth <= meanScreenWidth) {
												if (meanMenuExist === false) {
														showMeanMenu();
												}
										} else {
												meanOriginal();
										}
								}
						});

					showMeanMenu();
				});
        };
    }