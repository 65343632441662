export function clientInit(isHome) {
    const $ = require('jquery')
    isHome = isHome || false
    
    window.jQuery = $;
    window.$ = $;

    $(document).ready(function() {

        let scrolledEvt = new CustomEvent('booklineScrolled') 
        const headerSliderHeight = $('.single-slider').height()
        const topNavHeight = $('.default-header-area').height()
        const bookingAreaHeight = $('.booking-area').height()

        $('.book-now-btn, .booking-close').click(() => {
            $('.booking-area').toggleClass('expanded');    
        });   
    
        if (!isHome) {
            $('.book-now-btn').removeClass("d-none");
            scrolledEvt.calendarPosition = "bottom left"
            window.dispatchEvent(scrolledEvt);
        } else {
            $('.booking-area').css({marginTop: -bookingAreaHeight});
        }

        let topNavScrolledOut = false;   
        let bookingLineScrolledOut = false;    

        $(window).on('scroll',function() {

            if (!topNavScrolledOut && $(this).scrollTop() > topNavHeight){  
                $('.scroll-check').addClass("scrolled");
                $('.sticky-spacer').css({height: topNavHeight});
                if (isHome) {
                    $('.booking-area').css({marginTop: topNavHeight-bookingAreaHeight});
                }
                topNavScrolledOut = true;
            }
            else if (topNavScrolledOut && $(this).scrollTop() <= topNavHeight) {
                $('.scroll-check').removeClass("scrolled");
                $('.sticky-spacer').css({height: 0})
                if (isHome) {
                    $('.booking-area').css({marginTop: - bookingAreaHeight});
                }
                topNavScrolledOut = false;
            }

            if (isHome) {
    
                if (!bookingLineScrolledOut && $(this).scrollTop() > headerSliderHeight - topNavHeight){
                    $('.booking-area').addClass("scrolled");
                    $('.booking-area').css({marginTop: 0});
                    $('.booking-area-bottom-fix').css({height: bookingAreaHeight});
                    $('.book-now-btn').removeClass("d-none");
                    scrolledEvt.calendarPosition = "bottom left"
                    window.dispatchEvent(scrolledEvt);
                    bookingLineScrolledOut = true;
                } else if (bookingLineScrolledOut && $(this).scrollTop() <= headerSliderHeight - topNavHeight) {
                    $('.booking-area').removeClass("scrolled");
                    $('.booking-area').css({marginTop: + topNavHeight - bookingAreaHeight});
                    $('.book-now-btn').addClass("d-none");
                    $('.booking-area').removeClass("expanded");
                    $('.booking-area-bottom-fix').css({height: 0});
                    scrolledEvt.calendarPosition = "top left"
                    window.dispatchEvent(scrolledEvt);
                    bookingLineScrolledOut = false;
                }
            } 
        });
    })
}
