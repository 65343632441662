import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Meta = ({ description, lang, meta, image, keywords, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || site.siteMetadata.image
  const metaKeywords = keywords || (site.siteMetadata.keywords && site.siteMetadata.keywords.join(', '));


  title = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `thumbnail`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
      
      .concat(
        metaKeywords
          ? {
              name: 'keywords',
              content: metaKeywords,
            }
          : []
        )
        
        .concat(meta)}
        link={[
          {
            rel:`shortcut icon`,
            type:`image/x-icon`, 
            href:`/favicon.ico`
          },
          {
            rel:`apple-touch-icon`,
            sizes:`180x180`, 
            href:`/apple-touch-icon.png`
          },
          {
            rel:`apple-touch-icon`,
            type:`image/png`,
            sizes:`32x32`,  
            href:`/favicon-32x32.png`
          },
          {
            rel:`apple-touch-icon`,
            type:`image/png`,
            sizes:`16x16`,  
            href:`/favicon-16x16.png`
          }
        ]}
    />
  )
}

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default Meta